@import 'react-tooltip/dist/react-tooltip.css';

.react-tooltip {   
    opacity: unset;
    background-color: var(--color-white);
    color: var(--color-black);
    max-width: 500px;
    z-index: 9;
    border: 1px solid #E4E4E4;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.04em;
    padding: 15px 20px;

    .arrowLeft {
        border-left: 1px solid #E4E4E4;
        border-bottom: 1px solid #E4E4E4;
        z-index: 8;
    }

    .arrowBottom {
        border-bottom: 1px solid #E4E4E4;
        border-right: 1px solid #E4E4E4;
        z-index: 8;
    }
}
