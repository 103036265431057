/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

:root {
    --search-bar-max-width: 450px;
    --search-bar-width: 270px;
    --search-clear-background: var(--secondary-base-color);
    --search-field-background: var(--secondary-base-color);
    --search-field-border-color: var(--primary-divider-color);
    --search-field-color: var(--secondary-base-color);
    --search-field-visible-background: var(--secondary-base-color);
    --search-input-desktop-border-color: #000;
}

::placeholder {
    overflow: visible;
}

.SearchField {
    // display: none;
    margin: 8px auto;
    max-width: 100%;
    width: 100%;

    @include desktop {
        display: block;
        width: 80%;
        max-width: 600px;
        grid-column: 2 / 3;
        margin-inline-start: 20%;
    }

    &_isVisible {
        display: block;

        @include mobile {
            margin-block-start: 16px;
        }
    }

    &_isActive {
        .SearchOverlay {
            &-Results {
                display: block
            }
        }
    }

    &-CloseIcon,
    &-SearchIcon {
        position: absolute;
        height: 24px;
        inset-inline-end: 15px;
        inset-block-start: calc(50% - 12px);
        width: 24px;
        
        > svg {
            fill: var(--primary-base-color);
        }
    }

    &-Input {
        width: 100%;
        color: var(--color-white);
        background-color: var(--secondary-base-color);
        font-size: 16px;
        line-height: 26px;
        font-weight: 300;
        letter-spacing: 0.04em;
        border: 1px solid rgba(255, 255, 255, 0.5);

        &::placeholder {
            color: var(--color-white);
            opacity: 1;
        }
    }
}
