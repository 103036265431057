/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ResetAttributes {
    @include desktop {
        flex-grow: 1;
        display: flex;
        flex-wrap: wrap;
    }

    &-Group {
        display: flex;
        padding-block: 5px;

        @include desktop {
            margin-inline-end: 20px;
        }

        &Title {
            font-weight: bold;
            margin-inline-end: 20px;
        }

        &Values {
            display: flex;
        }
    }

    &-Attribute {
        &Label {
            font-weight: normal;
            padding-inline-start: 5px;
        }

        &Value {
            margin: 0 20px 0 0;

            .CloseIcon {
                display: block;
            }
        }
    }
}
