/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.RangeSliderInput {
    padding: 10px;

    &-Wrapper {
        display: flex;
        width: 100%;
    }

    &-Track {
        height: 10px;
        width: 100%;
        border-radius: 7px;
        align-self: center;
    }

    &-Thumb {
        height: 20px;
        width: 20px;
        border-radius: 10px;
        background-color: #fff;
        border: 1px solid #fff;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);

        &_isDragged, &:hover {
            border-color: var(--primary-base-color);
        }
    }

    &-LabelWrapper {
        margin-block-start: 20px;
        display: flex;
        align-items: center;
    }

    &-Label {
        font-weight: bold;
        margin-inline-end: 10px;
    }

    &-Value {
        font-weight: 300;
        display: flex;
        align-items: center;
    }

    &-ToolTip {
        width: 15px;
        margin-inline-start: 10px;

        p {
            margin: 0;
        }
    }

    &-ToolTipToggle {
        svg {
            display: block;
            height: 12px;
            width: 12px;
        }
    }
}
