/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

:root {
    --search-criteria-background: var(--secondary-base-color);
    --search-criteria-label-color: rgba(10, 10, 10, .44);
    --search-criteria-search-term-color: #0a0a0a;
}

.SearchOverlay {
    &_isVisible {
        .SearchOverlay {
            &-Results {
                display: block;
            }
        }
    }

    &-ResultWrapper {
        max-height: 100%;
        height: 100%;
        min-height: 100%;
        overflow: hidden;
    }

    &-Results {
        background: #FBFAFA;
        display: none;
        z-index: 110;
        width: 100%;
        padding: 14px;


        .NoResults {
            // padding: 20px;
            margin: 0;
        }

        @include desktop {
            position: fixed;
            width: calc(100% - 40px);
            max-width: 1000px;
            box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
            inset-inline-start: 50%;
            inset-block-start: 127px;
            transform: translateX(-50%);
            padding: 30px;
            height: 65vh;
            overflow: hidden;

            ul {
                max-height: 100%;
                overflow-y: auto;
                padding-block-end: 80px;
            }

            h3 {
                font-weight: 500;
                font-size: 17px;
                border-bottom: 1px solid #E7E6E5;
                margin-block-end: 30px;
                margin-block-start: 0px;
            }
        }
    }

    &-Sidebar {
        .Sidebar-CategoryList {
            li {
                &::before {
                    display: none;
                }

                a {
                    font-weight: 300;
                    font-size: 17px;
                    letter-spacing: 0.04rem;
                    color: #1E1E1E;
                }
            }
        }
    }
}
