/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

:root {
    --color-pagination-link-text: var(--imported_pagination_color, #{$black});
    --color-pagination-link-active-text: var(--primary-base-color);
    --pagination-line-height: var(--imported_pagination_line_height, 20px);
    --pagination-font-size: var(--imported_pagination_font_size, 14px);
    --pagination-font-style: var(--imported_pagination_font_style, normal);
}

.PaginationLink {
    color: var(--color-pagination-link-text);
    font-size: var(--pagination-font-size);
    font-style: var(--pagination-font-style);
    font-weight: normal;
    display: block;
    line-height: var(--pagination-line-height);
    margin-inline-end: 15px;
    padding: 7px 13px;
    text-align: center;
    background-color: #ffffff;

    &_isArrow {
        padding: 7px 11px;
        background-color: transparent;

        &:hover {
            svg {
                fill: var(--body-content-color);
            }
        }
    }

    &:hover {
        color: var(--primary-base-color);
    }

    &_isCurrent {
        --color-pagination-link-text: #ffffff;

        background-color: var(--body-content-color);
    }
}
