/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/* eslint-disable */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --footer-content-height: 220px;
    --footer-copyright-height: 130px;
    --footer-nav-height: 48px;
    --footer-total-height: calc(env(safe-area-inset-bottom) + var(--footer-nav-height));
}

.Footer {
    $column-count: 5;
    
    background: var(--secondary-base-color);

    p, li, span, a {
        color: var(--color-white);
        font-size: 16px;
        line-height: 26px;
        font-weight: 300;
    }

    strong {
        color: var(--color-white);
        font-size: 16px;
        line-height: 26px;
        font-weight: 700;
    }

    a {
        cursor: pointer;
    }

    @include mobile {
        margin-block-end: var(--navigation-tabs-height);
    }

    &-Content {
        min-height: var(--footer-content-height);
        background-color: var(--secondary-base-color);
    }

    &-NewsletterWrapper {
        background: #F4F1EC;
        padding-block: 30px;
    }

    &-Column_isNewsletter {
        display: grid;
        grid-template-columns: 1fr 2fr;
        align-items: center;

        .Footer-ColumnTitle {
            font-weight: 300;
            font-size: 30px;
            color: var(--color-black);
            margin-block-end: 0px;
        }

        .NewsletterSubscription {
            .FieldForm-Body {
                display: grid;
                grid-template-columns: 3fr 1fr;
                align-items: center;

                .Field_type_email {
                    input {
                        background: transparent;
                        border: none;
                        border-bottom: 1px solid var(--color-black);
                        font-size: 18px;
                        font-weight: 300;
                        letter-spacing: 0.04em;
                        color: var(--color-black);

                        &::placeholder {
                            font-size: 18px;
                            font-weight: 300;
                            letter-spacing: 0.04em;
                            color: var(--color-black);
                            opacity: 1;
                        }
                    }
                }

                .Button {
                    margin: 0px;
                    padding: 0px;
                    margin-inline-start: 70px;
                    font-weight: 700;
                    font-size: 14px;
                    letter-spacing: 00.04em;
                    width: 80%;
                    justify-self: flex-start;
                }
            }
        }
    }

    &-Columns {
        display: flex;
        height: 100%;
        justify-content: space-between;
        padding-block-end: 60px;

        @include mobile {
            flex-direction: column;
            align-items: center;
            text-align: center;
            padding: 25px 16px;
        }

        @include desktop {
            padding-inline: 32px;
            padding-block-start: 80px;
        }

        @include tablet {
            padding-inline: 30px;
        }
    }

    &-Column {
        @include mobile {
            width: 100%;
        }

        &:not(:last-of-type) {
            @include mobile {
                margin-block-end: 30px;
            }

            @include desktop {
                padding-inline-end: 20px;
                max-width: calc(100% / #{ $column-count - 1 });
            }
        }
    }

    &-ColumnTitle {
        margin: 0;
        margin-block-end: 15px;
        color: var(--primary-base-color);
        font-weight: 300;
        font-size: 20px;
        line-height: 24px;

        @include desktop {
            margin-block-end: 20px;
        }
    }

    &-ColumnContent {
        display: flex;
        flex-direction: column;

        &_direction_horizontal {
            flex-direction: row;

            @include mobile {
                justify-content: center;
            }
        }
    }

    &-ColumnItem {
        border-radius: 2px;
        color: inherit;
        margin-block-end: 12px;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;

        &:last-of-type {
            margin: 0;
        }

        &:hover {
            text-decoration: underline;
        }

        &Image {
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                // important required as default 100% width is passed as inline style
                // + you can't achieve same centering with just passing '16px' as inline style for the case
                /* stylelint-disable-next-line declaration-no-important */
                width: 16px!important;
                inset-block-start: 50%;
                inset-inline-start: 50%;
                transform: translate(-50%, -50%);

                &:dir(rtl) {
                    transform: translate(50%, -50%);
                }

                &:hover {
                    opacity: 0.6;
                }
            }
        }

        &_type_image {
            width: 40px;
            height: 40px;
            border: 1px solid var(--secondary-dark-color);

            &:not(:last-of-type) {
                margin-inline-end: 20px;
            }
        }
    }



    &-CmsBlockWrapper {
        .CmsBlock-Wrapper {
            flex: 1;
        }

        .ContentWrapper .Footer-Columns {
            padding: 0;
        }

        .Footer-Column_isNewsletter {
            @include desktop {
                padding-inline-start: 100px;
            }
        }
    }

    .CmsBlock-Wrapper_type_payment {
        ul {
            float: inline-start;
            margin-inline-end: 15px;
        }
    }

    &-PaymentWrapper {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-auto-columns: 66px;
        grid-auto-rows: 35px;
        gap: 8px;
    }

    &-Paymentcard {
        background-color: transparent;
        border: 1px solid rgba(255, 255, 255, 0.12);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
    }

    &-Listing {
        li {
            &::before {
                position: unset;
                content: '-';
                padding-inline-end: 5px;
                color: var(--primary-base-color);
            }
        }
    }

    &-CopyrightContentWrapper {
        background: linear-gradient(180deg, rgba(20, 19, 19, 0.6) 0%, rgba(49, 44, 44, 0) 88.54%);
        // background-color: black;
    }

    &-CopyrightContent {
        padding: 50px 0;
        min-height: var(--footer-copyright-height);

        .CmsBlock-Wrapper {
            > div {
                display: grid;
                grid-template-columns: 4fr 2fr 1fr;
            }
        }

        @include desktop {
            padding-inline: 32px;
            padding-block-end: 48px;
        }

        a {
            color: var(--paragraph-color);
            font-weight: normal;
        }
    }

    &-Info {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        padding-inline-end: 50px;

        &-Card {
            display: flex;
            flex-direction: row;
            align-items: center;

            p {
                font-size: 13px;
                font-weight: 400;
                letter-spacing: 0.02rem;
                line-height: 17px;
                margin-block-end: 0px;
            }

            svg {
                width: 50px;
                height: 50px;
                padding-inline-end: 15px;
                fill: var(--primary-base-color);
                flex-shrink: 0;
            }
        }
    }

    &-Copyright {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        opacity: 0.5;

        a, span, p {
            color: var(--color-white);
            font-weight: 300;
            font-size: 12px;
            margin-block-end: 0px;
        }
    }

    &-Socials {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        > * {
            padding-inline-start: 20px;
        }

        .Socials-Heading {
            font-size: 12px;
            font-weight: 700;
            text-transform: uppercase;
        }
    }

    .Footer-VideoButton {
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
        line-height: 17px;
        height: 35px;
        width: 100%;

        &:hover {
            font-size: 12px;
            font-weight: 700;
            text-transform: uppercase;
            line-height: 17px;
            height: 35px;
        }
    }
}
