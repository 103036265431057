/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

:root {
    --menu-item-height: 20px;
    --menu-total-height: 74px;
}

@mixin subcategory-visible {
    pointer-events: all;
    z-index: 10;
    opacity: 1;
    transform: translateX(0);
}

.Menu {
    &-CompareLink {
        color: var(--color-black);
        font-size: 14px;
        font-weight: 400;
        text-transform: none;
        display: flex;
        align-items: center;

        .CompareIcon {
            margin-inline-end: 12px;

            &:hover {
                fill: var(--color-black);
            }
        }

        &Wrapper {
            border-block-end: 1px solid var(--primary-divider-color);
            padding-block-start: 17px;
            padding-block-end: 16px;
            padding-inline: 0;
            margin: 0 16px;

            @include mobile {
                padding: 13px 0;
            }
        }
    }

    &-CompareCountInMenu {
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        inset-block-start: -1px;
        margin-inline-start: auto;
    }

    &-ExpandedState {
        --plus-minus-icon-size: 18px;

        display: inline-block;
        float: inline-end;

        &:dir(rtl) {
            float: inline-start;
        }

        position: absolute;
        inset-inline-end: 0;

        @include desktop {
            display: none;
        }
    }

    &-Item {
        padding-inline-start: 0;

        @include desktop {
            margin: 0;
            height: 100%;
            display: flex;
            align-items: center;
            margin-inline: 80px;
        }

        &::before {
            content: none;
        }

        &Caption {
            color: var(--color-white);

            &:hover {
                color: var(--color-white);

                @include mobile {
                    color: var(--color-black);
                }
            }

            @include mobile {
                margin-inline: 16px;
                padding-block: 16px;
                color: var(--color-black);
                border-bottom: 1px solid #e7e6e5;
            }

            &_isSecondLevel {
                font-weight: unset;
            }

            &_type {
                &_main {
                    @include desktop {
                        // margin: 0 0 16px;
                    }

                    text-transform: none;
                    font-weight: 300;
                    line-height: 27px;
                    font-size: 19px;
                    letter-spacing: 0.04em;
                }

                &_subcategory {
                    line-height: 20px;

                    @include mobile {
                        margin-inline-end: 0;
                    }

                    @include desktop {
                        margin-block-start: 12px;
                        display: inline-block;
                        width: 80%;
                        border-bottom: 1px solid #E7E6E5;
                        margin-block-end: 20px;
                        font-size: 17px;
                        font-weight: 500;
                        letter-spacing: 0.04em;
                    }
                }
            }
        }

        &List {
            @include desktop {
                display: flex;
                height: 100%;
                align-items: center;
                
                .Menu-SubMenu & {
                    display: block;
                }
            }

            &_type_subcategory {
                @include mobile {
                    padding-block: 8px;
                }
            }
        }

        &:not(:first-of-type) {
            @include desktop {
                margin-inline-start: 20px;
            }
        }

        &:last-child {
            margin-block-end: 0px;
        }
    }

    &-Link {
        display: contents;
        font-weight: 400;
        margin-block: 8px;
        margin-inline: 0 16px;
    }

    &-MainCategories {
        margin: 0 auto;

        @include desktop {
            width: fit-content;
            height: 100%;

            .Menu-Link {
                font-weight: 700;
                display: flex;
                height: 100%;
                align-items: center;
                margin: 0;

                &_isHovered {
                    border-bottom: 5px solid var(--primary-base-color);

                    .Menu-ItemCaption {
                        margin-block-start: 5px;
                    }
                }
            }
        }

        @include mobile {
            .SearchField {
                padding: 0 16px;

                &-Input {
                    background-color: #F4F1EC;
                    color: #1E1E1E;

                    &::placeholder {
                        color: #1E1E1E;
                    }
                }

                &-SearchIcon {
                    svg {
                        fill: #1E1E1E;
                    }
                }
            }
        }
    }

    &-MenuWrapper {
        @include desktop {
            background: linear-gradient(
                180deg, 
                rgba(20, 19, 19, 0.6) 0%, 
                rgba(49, 44, 44, 0) 60%
            );
            margin-block-start: 18px;
            height: var(--menu-total-height);
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
        }
    }

    &-SubCategoriesWrapper {
        @include desktop {
            position: fixed;
            inset-block-start: calc(var(--header-total-height) + 18px);
            inset-block-end: 0;
            width: 100%;
            background-color: rgba(30,30,30, 0.85);
            // height: 100vh;
        }
    }

    &-SubCategoriesWrapperInner {
        width: 80%;
        margin: 0 auto;
        background-color: #FBFAF8;
        max-height: 100%;
        overflow-y: auto;

        .Menu-ReadMoreWrapper {
            padding-block: 12px;
            display: none;

            @include desktop {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                margin-block-end: -40px;
            }

            .Menu-ReadMore {
                display: flex;
                align-items: center;

                span {
                    color: var(--color-black);
                    font-weight: 500;
                    font-size: 14px;
                }
            }
        }

        &_isAccessoryMenu {
            height: auto;

            @include desktop {
                .Menu-ItemCaption {
                    font-size: 17px;
                }

                .Menu-ItemList {
                    .Menu-Link {
                        font-size: 16px;
                        font-weight: 300;
                    }
                }
            }
        }
    }

    &-SubCategories {
        @include desktop {
            padding-inline: 55px;
            padding-block: 20px;
            padding-block-end: 45px;
        }
    }

    &-Sub {
        &ItemWrapper {
            > .Menu-Link {
                @include desktop {
                    font-weight: 700;
                }
            }

            break-inside: avoid;
            overflow: hidden;
        }

        &Menu {
            display: none;
            // margin-block-end: 28px;

            &_isVisible {
                background: var(--color-gray);
                display: block;
                padding-inline: 16px;

                @include mobile {
                    margin-block-end: 0;
                }
            }

            @include desktop {
                background: none;
                display: block;
                padding: 0;
                height: 100%;
            }

            .Menu-SubMenu & {
                padding-block-end: 0;
            }

            .Menu-ItemCaption {
                @include mobile {
                    padding-block: 8px;
                    text-transform: none;
                    font-weight: 300;
                    font-size: 16px;
                    letter-spacing: 0.04em;
                }

                @include desktop {
                    padding-block-end: 8px;
                    color: var(--color-black);
                }
            }

            > .Menu-ItemList {
                @include desktop {
                    columns: 4;
                }
            }

            &.submenu-zigarren > .Menu-ItemList {
                @include desktop {
                    display: grid;
                    grid-template-columns: 1fr 4fr;
                    grid-template-rows: 1fr 1fr;
                    gap: 15px 0px;
                    grid-auto-flow: row;
                    grid-template-areas: "categories brands" "countries brands";
                    align-items: flex-start;
                    border-bottom: 1px solid #E7E6E5;
                    height: 100%;

                    .Menu-SubLevelDesktop .Menu-ItemCaption {
                        font-size: 16px;
                        letter-spacing: 0.04em;
                        font-weight: 300;
                    }

                    .Menu-SubItemWrapper {
                        height: 100%;
                        overflow: auto;
                    }
    
                    .Menu-SubItemWrapper:nth-child(1) {
                        grid-area: categories;
                        margin-inline-end: 50px;
                        margin-block-end: 15px;

                        .Menu-SubLevelDesktop .Menu-ItemCaption {
                            font-weight: 500;
                        }
                    }
    
                    .Menu-SubItemWrapper:nth-child(2) {
                        grid-area: countries;
                        margin-inline-end: 50px;
                    }
    
                    .Menu-SubItemWrapper:nth-child(3) {
                        grid-area: brands;

                        .Menu-ItemCaption_type_subcategory {
                            width: 100%;
                        }
    
                        .Menu-ItemList {
                            columns: 5;
                            overflow: auto;
                        }
                    }
                }
            }

            .Menu-ItemList_SubMenuId_440 {
                @include desktop {
                    .Menu-ItemCaption_type_subcategory {
                        border: none;
                    }

                    .Menu-ReadMoreWrapper {
                        display: none;
                    }
                }
            }

            &.submenu-accessories {
                @include desktop {
                    > .Menu-ItemList {
                        display: grid;
                        grid-template-columns: repeat(4, 1fr);
                        height: auto;
                        gap: 20px 0;
                    }
                }
            }

            &.submenu-simple {
                @include desktop {
                    margin-block-end: -25px;

                    > .Menu-ItemList {
                        display: grid;
                        grid-template-columns: repeat(4, 1fr);
                        height: auto;
                        gap: 1rem 0;

                        .Menu-ItemCaption {
                            border: 0;
                            margin: 0;
                            padding: 1rem 0;
                        }
                    }
                }
            }
        }
    }
}

.MenuPage {
    display: flex;
    flex-direction: column;

    .Menu-MenuWrapper {
        display: flex;
        height: 100%;
        flex-grow: 1;
        
        .Menu-MainCategories {
            display: flex;
            flex-direction: column;
            flex-grow: 1;

            .Menu-ItemList {
                height: 100%;
                flex-grow: 1;
            }

            .StoreSwitcher {
                display: flex;
                justify-content: flex-end;
                border: none;
                background-color: #FBFAFA;
                border-top: 1px solid var(--primary-divider-color);
                position: fixed;
                inset-block-end: 48px;
                width: 100%;
                padding-block-start: 2px;
                padding-inline-start: 15px;
                padding-inline-end: 15px;
                margin: 0;
                transition: transform 200ms cubic-bezier(0.47, 0, 0.745, 0.715);
                
                .Field-Wrapper {
                    width: 30%;

                    select {
                        background-color: #FBFAFA;
                    }
                }

                .hideOnScroll & {
                    transform: translateY(100%);
                }
            }
        }
    }
}
