$layout__max-width: 1400px;

.CmsPage {
    [data-content-type='row'][data-appearance='contained'] {
        box-sizing: border-box;
        margin-inline: auto;
        max-width: var(--content-wrapper-width);
        padding-inline: 32px;

        [data-element='inner'] {
            box-sizing: border-box;
            border-top: 1px solid #E7E6E5;
        }

        &:last-child {
            margin-block-end: 50px;
        }

        img {
            width: 100%;
        }
    }

    .subbrands-wrapper {
        display: grid;
        grid-auto-rows: max-content;
        grid-column-gap: 20px;
        grid-template-columns: repeat(6, 1fr);

        > div {
            background-color: var(--color-white);
            min-height: 240px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: 10px;
            text-align: center;

            span {
                font-size: 20px;
                font-weight: 300;
                line-height: 24px;
            }

            a {
                color: var(--color-black);

                &:last-child {
                    margin-block-start: 20px;
                }
            }
        }
    }

    h3 {
        border-top: 1px solid #E7E6E5;
        margin-block-start: 50px;
        padding-block-start: 30px;
    }

    .All-Products {
        margin-block-start: 75px;
        color: var(--color-black);
        background-color: #F4F1EC;
        border: none;
    }

    //  Mobile (style-m.css)
    //  _____________________________________________

    @include mobile {
        [data-content-type='row'][data-appearance='contained'] {
            padding-inline: 14px;

            [data-element='inner'] {
                background-attachment: scroll;
            }
        }
    }
}
