/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

:root {
    --search-item-hover-color: #eee;
    --search-item-divider-color: #eee;
    --search-item-title-color: #000;
}

.SearchItem {
    padding: 30px;
    background-color: var(--color-white);
    margin-block-end: 25px;

    @include mobile {
        padding: 15px;
    }

    &::before {
        content: none;
    }

    &:hover {
        background-color: var(--search-item-hover-color);
    }

    &-CustomAttribute {
        color: var(--color-black);
        font-size: 12px;
        font-weight: 400;
        opacity: .5;
    }

    &-Wrapper {
        display: grid;
        grid-template-columns: 200px calc(100% - 250px);
        grid-column-gap: 50px;

        @include mobile {
            grid-template-columns: 100px calc(100% - 125px);
            grid-column-gap: 25px;
        }

        .Image {
            padding-block-end: 0px;
        }
    }

    &-PriceWrapper {
        display: flex;
        align-items: center;
        padding-block-start: 5px;

        .ProductPrice {
            min-height: unset;
            line-height: 16px;
        }

        span {
            font-size: 16px;
            font-weight: 500;
            line-height: 16px;
            margin-block-start: 0px;
        }
    }
}
