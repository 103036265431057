// Font declaration
@font-face {
    font-family: 'AnekMalayalam';
    src: url('/style/fonts/AnekMalayalam-Light.woff2') format('woff2'),
        url('/style/fonts/AnekMalayalam-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'AnekMalayalam';
    src: url('/style/fonts/AnekMalayalam-Medium.woff2') format('woff2'),
        url('/style/fonts/AnekMalayalam-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'AnekMalayalam';
    src: url('/style/fonts/AnekMalayalam-SemiBold.woff2') format('woff2'),
        url('/style/fonts/AnekMalayalam-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'AnekMalayalam';
    src: url('/style/fonts/AnekMalayalam-Bold.woff2') format('woff2'),
        url('/style/fonts/AnekMalayalam-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

// Apply fonts

body {
    font-family: 'AnekMalayalam', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'AnekMalayalam', sans-serif;
}

del {
    text-decoration: none;
    background: linear-gradient(currentColor, currentColor) no-repeat center .45em / 100% 1px
}


