/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.CigarFinderImages {
    display: flex;
    position: relative;
    margin-block-end: 20px;
    grid-column: 1 / 3;
    
    @include desktop {
        margin-block-start: -20px;
    }

    &-Image {
        width: calc(100% / 24 - 20px);
        margin-inline: 10px;
        display: flex;
        justify-content: center;
        overflow: hidden;

        &Wrapper {
            width: 1000%;
            padding-block-start: 1000%;
            position: relative;
            flex-shrink: 0;

            &Inner {
                position: absolute;
                inset-block-start: 0;
                inset-inline-start: 0;
                height: 100%;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                transform: rotate(90deg);
            }
        }
        
        img {
            display: block;
            cursor: pointer;
        }
    }
}
