/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

.CmsPage {
    &_isBreadcrumbsHidden {
        margin-block-start: calc(var(--header-total-height) + 12px);

        @include mobile {
            margin-block-start: calc(var(--header-total-height) + 14px);
        }
    }

    &-Content {
        @include mobile {
            max-width: 100%;
            overflow-x: hidden;
        }

        h2 {
            @include mobile {
                // text-align: center;
            }
        }

        div[data-content-type="row"] {
            // border-top: 1px solid #E7E6E5;
            // padding-block-start: 30px;
            margin-block-start: 80px;

            @include mobile {
                margin-block-start: 40px;
            }
        }

        div[data-content-type="row"]:first-child {
            border-top: none;
            padding-block-start: 0px;
            margin-block-start: 0px;
        }
    }

    &-Wrapper {
        grid-template-columns: auto;
        max-width: var(--content-wrapper-width);
        padding-inline: 32px;
        margin-block-start: calc(var(--header-total-height) + 12px);
        margin-block-end: var(--header-nav-height);
        margin-inline: auto;
        word-wrap: break-word;

        @include mobile {
            padding-inline: 14px;
            margin-block-start: calc(var(--header-total-height));
        }

        @include desktop {
            margin-block-start: 24px;
        }

        &_page_width {
            &_full {
                max-width: 100%;
                padding-inline: 0;
            }
        }
    }

    &-Heading {
        margin-block-end: 18px;

        @include mobile {
            margin-block-end: 21px;
            display: none;
        }
    }

    &-SectionPlaceholder {
        background-image: var(--placeholder-image);
        background-size: var(--placeholder-size);
        animation: var(--placeholder-animation);
        margin: 0;
        margin-block-end: 10px;
        padding-block-end: 30%;

        @include mobile {
            padding-block-end: 60%;
        }

        &:first-child {
            padding-block-end: 40%;

            @include mobile {
                padding-block-end: 100%;
            }
        }
    }

    .TextPlaceholder {
        margin-block-end: 22px;
        display: block;

        @include mobile {
            margin-block-end: 25px;
        }
    }

    .Slider-Crumbs {
        margin-block: 16px 40px;

        @include mobile {
            margin-block: 0px 0px;
        }
    }

    h3 {
        @include mobile {
            font-size: 14px;
        }
    }

    .pagebuilder-column {
        .ProductListWidget-Page {
            grid-template-columns: repeat(2, 1fr);

            @include mobile {
                grid-template-columns: repeat(1, 1fr);
            }
        }

        .CigarFinderBlockHomeWrapper {
            display: flex;
            flex-direction: column;
            margin-inline-start: 60px;
            margin-block-start: 88px;

            @include mobile {
                margin-inline-start: 0;
            }

            .CigarFinderBlockHome {
                background-color: #F4F1EC;
                padding: 35px;
                border-bottom: 1px solid var(--color-white);

                img {
                    width: 100%;
                    height: auto;
                    padding-block: 40px;
                }
            }
        }
    }

    .pagebuilder-slide-wrapper {
        .pagebuilder-poster-content {
            padding: 50px 32px;
            max-width: var(--content-wrapper-width);
            // max-width: 60%;
            // background-color: rgba($color: #000000, $alpha: 0.5);

            @include mobile {
                padding: 0px 15px; 
            }
            
            div[data-element='content'] {
                h2 {
                    font-size: 50px;
                    font-weight: 300;
                    letter-spacing: 00.04em;
                    color: var(--color-white);
                    line-height: 52px;
                    margin-block-start: 0px;

                    @include mobile {
                        font-size: 32px;
                        line-height: 38px;
                    }
                }

                p {
                    font-size: 28px;
                    font-weight: 300;
                    letter-spacing: 00.04em;
                    line-height: 40px;
                    color: var(--color-white);

                    @include mobile {
                        font-size: 22px;
                        line-height: 28px;
                    }

                    &:last-child {
                        @include mobile {
                            // text-align: center;
                            margin-block-end: 0px;
                        }
                    }
                }

                a {
                    align-items: center;
                    background-color: var(--button-background);
                    border-radius: var(--button-border-radius);
                    cursor: pointer;
                    display: inline-flex;
                    color: var(--button-color);
                    font-size: 14px;
                    font-weight: 700;
                    justify-content: center;
                    height: var(--button-height);
                    line-height: normal;
                    padding-inline-start: var(--button-padding);
                    padding-inline-end: var(--button-padding);
                    text-decoration: var(--button-text-decoration);
                    text-transform: uppercase;
                    transition-duration: .25s;
                    transition-timing-function: ease-out;
                    transition-property: background-color, color, border;
                    will-change: background-color, color, border;
                    border-color: var(--button-border);
                    border-style: solid;
                    border-width: var(--button-border-width);
                }
            }
        }
    }
    // ul,
    // ol {
    //     li {
    //         &::before {
    //             position: relative;
    //             margin-inline-start: 10px;
    //             margin-inline-end: 5px;
    //             inset-block-start: -1px;
    //         }
    //     }
    // }
}
