/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --header-logo-width: 452px;
    --header-logo-height: 49px;

    --header-height: 60px;
    --header-nav-height: 60px;
    --header-total-height: calc(var(--header-height) + var(--offline-notice-height) + var(--demo-notice-height) + env(safe-area-inset-top));

    @include desktop {
        --header-top-menu-height: 48px;
        --header-height: calc(var(--header-nav-height) + var(--header-top-menu-height) + var(--menu-total-height));
    }
}

@mixin button-invisible {
    margin: 0;
    opacity: 0;
    overflow: hidden;
    padding: 0;
    pointer-events: none;
    width: 0;
    height: 0;
}

@mixin button-visible {
    opacity: 1;
    height: 25px;
    width: 25px;
    pointer-events: all;
    overflow: visible;
    cursor: pointer;
}

@mixin visible-on-mobile {
    @include button-visible;

    @include desktop {
        @include button-invisible;
    }
}

@mixin logo-visible {
    opacity: 1;
    // max-width: 100%;
}

.Header {
    align-items: center;
    background: var(--secondary-base-color);
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding-block-start: env(safe-area-inset-top, 0);
    width: 100%;
    position: fixed;
    z-index: 100;
    inset-block-start: var(--demo-notice-height);

    > * {
        // max-width: var(--content-wrapper-width);
        width: 100%;
    }

    &_menu,
    &_menu_subcategory {
        .Header-Button_type {
            &_close {
                @include desktop {
                    @include button-visible;
                }
            }

            &_menu {
                @include desktop {
                    @include button-invisible;
                }
            }
        }
    }

    &_name {
        &_menu {
            border-block-end: none;
        }

        &_search,
        &_menu_subcategory {
            border-block-end: 1px solid var(--primary-divider-color);
        }

        &_category {
            position: fixed;

            @include mobile {
                border-block-end: none;
            }
        }
    }

    &-Button {
        @include button-invisible;

        &_isVisible {
            @include mobile {
                @include button-visible;

                height: 20px;
                width: 20px;
            }
        }

        &_isDisabled {
            pointer-events: none;
            opacity: .1;
        }

        &_type {
            &_menu,
            &_minicart {
                @include button-visible;

                @include desktop {
                    height: 24px;
                    width: 100%;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                }
            }

            &_account {
                @include button-visible;
            }

            &_home {
                @include visible-on-mobile;
            }

            // &_minicart {
            //     @include desktop {
            //     }
            // }

            &_back {
                .ChevronIcon {
                    width: 20px;
                    height: 20px;
                    vertical-align: middle;
                }
            }

            &_close {
                .CloseIcon {
                    width: 27px;
                    height: 27px;
                }
            }

            &_share {
                @include button-visible;
            }

            &_compare {
                @include button-visible;
            }
        }

        &[aria-hidden="true"] {
            display: none;
        }
    }

    &-IconsWrapper {
        display: flex;
        justify-content: flex-end;

        .MiniCart-Wrapper {
            > div {
                display: flex;
                margin-block-start: 10px;
            }

            .Header-MiniCartButtonPriceWrapper {
                display: flex;
                flex-direction: column;
                min-width: 80px;
                text-align: end;
                padding-inline-end: 12px;

                .MiniCartButtonPriceWrapper-Title {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 12px;
                    letter-spacing: 0.04em;
                    color: var(--color-white);
                }

                .MiniCartButtonPriceWrapper-CurrencyPrice {
                    font-weight: 300;
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing: 0.04em;
                    color: var(--color-white);
                }
            }
        }
    }

    &-MinicartButtonWrapper {
        cursor: pointer;
        height: 100%;
        width: 100%;
    }

    &-CompareButtonWrapper {
        margin-inline-start: 35px;
        height: 24px;

        a {
            all: initial;
        }
    }

    &-CompareCount {
        background: #0A0903;
        border-radius: 8px;
        color: var(--color-white);
        height: 17px;
        padding: 2px;
        position: absolute;
        min-width: 17px;
        display: flex;
        font-size: 11px;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        font-family: "Muli", sans-serif;

        @include mobile {
            inset-block-start: -7px;
            inset-inline-end: -11px;
        }

        @include desktop {
            inset-block-start: -10px;
            inset-inline-end: -12px;
        }
    }

    &-LogoWrapper {
        inset-block-end: 0;
        height: var(--header-logo-height);
        inset-inline-start: 0;
        opacity: 0;
        overflow: hidden;
        inset-inline-end: 0;
        inset-block-start: 0;
        // width: var(--header-logo-width);

        @include mobile {
            margin: auto;
            width: 80%;
            text-align: center;
        }

        @include desktop {
            @include logo-visible;
            
            grid-column: 1 / 2;
            min-height: var(--header-nav-height);
            position: absolute;            
            max-width: 90%;
        }

        &_isVisible {
            @include logo-visible;
        }
    }

    &-Minicart {
        &ItemCount {
            background: var(--primary-base-color);
            border-radius: 8px;
            color: var(--color-white);
            height: 17px;
            padding: 2px;
            position: absolute;
            min-width: 17px;
            display: flex;
            font-size: 11px;
            justify-content: center;
            align-items: center;
            font-weight: 700;

            @include mobile {
                inset-block-start: -7px;
                inset-inline-end: -11px;
            }

            @include desktop {
                inset-block-start: 12px;
                inset-inline-end: 12px;
            }
        }
    }

    &-Nav {
        align-items: center;
        display: flex;
        height: var(--header-nav-height);
        margin: auto;
        max-width: var(--content-wrapper-width);
        padding-inline: 14px;

        @include desktop {
            display: grid;
            grid-template-columns: minmax(var(--header-logo-width), 1fr) minmax(auto, 660px) 1fr;
            justify-content: unset;
            padding-inline: 32px;
        }
    }

    &-MyAccount {
        @include desktop {
            // height: 24px;
        }

        &Wrapper {
            display: flex;
            align-items: center;
            justify-content: center;

            * {
                color: var(--color-white);
            }

            .myAccount-Text {
                padding-inline-start: 10px;
                cursor: pointer;
                font-size: 14px;
                letter-spacing: 0.04em;
                font-weight: 300;
            }
        }
    }

    &-Login {
        margin-inline-end: 38px;
    }

    &-Wishlist {
        margin-inline-end: 50px;

        .Header-Link {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;

            .Button-Text {
                color: var(--color-white);
                font-weight: 300;
                letter-spacing: 0.04em;
            }

            .HeartIcon {
                height: 16px;
                margin-inline-end: 10px;
            }
        }
    }

    &-News {
        align-items: center;
        display: flex;

        &Button {
            font-size: 12px;
            margin-inline-start: 5px;
        }

        .ExclamationMarkIcon {
            inset-block-end: 1px;
            height: 16px;
            margin-inline-end: 10px;
            width: 16px;
        }
    }

    &-Title {
        color: var(--header-color);
        margin: auto;
        opacity: 0;
        overflow: hidden;
        pointer-events: none;
        position: absolute;
        inset-inline-end: 0;
        text-align: center;
        text-overflow: ellipsis;
        inset-block-start: -1px;
        inset-inline-start: 0;
        inset-block-end: 0;
        transition-duration: 200ms;
        transition-property: opacity;
        white-space: nowrap;
        width: 100%;
        text-transform: inherit;
        line-height: 30px;
        height: 30px;

        @include mobile {
            line-height: 30px;
            color: var(--color-white);
        }

        &_isVisible {
            @include mobile {
                opacity: 1;
                max-width: calc(100% - 80px);
            }
        }
    }

    &-Welcome {
        display: none;
        font-size: 14px;
        text-align: end;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 200px;
        align-self: center;
        line-height: 16px;
        font-weight: 300;
        letter-spacing: 0.04em;

        @include wide-desktop {
            display: block;
        }
    }

    &-TopMenu {
        display: flex;
        justify-content: flex-end;
        height: var(--header-top-menu-height);
        margin: auto;
        max-width: var(--content-wrapper-width);
        padding-inline: 32px;
        padding-block: 16px;
        align-items: center;
    }

    &-Switcher {
        align-items: center;
        display: flex;

        .StoreSwitcher-Title {
            color: var(--color-white);
            font-weight: 300;
            font-size: 14px;
            letter-spacing: 0.04em;
        }

        .StoreItem-Item {
            &:hover {
                color: var(--color-white);
            }
        }
    }

    &_isCheckout {
        .Header {
            &-Button_type_account {
                display: block;
            }

            &-Button_type_close,
            &-Button_type_back {
                @include desktop {
                    display: none;
                }
            }

            &-MyAccount {
                @include desktop {
                    margin-inline-start: auto;
                    height: 24px;
                }

                @include mobile {
                    position: absolute;
                    inset-inline-end: 10px;
                    width: auto;
                }
            }
        }

        .MyAccountOverlay {
            @include mobile {
                inset-block-start: var(--header-total-height);
                height: calc(100vh - var(--header-total-height));
                position: fixed;
                width: 100vw;
            }
        }
    }

    &-Wrapper {
        display: flex;
        justify-content: center;
        margin-block-end: calc(var(--header-total-height));

        @include desktop {
            margin-block-end: calc(var(--header-total-height) + 18px);
        }

        &_isPrerendered {
            @include mobile {
                .Header-TopMenu,
                .Menu-MenuWrapper {
                    display: none;
                }

                .Header-Nav {
                    & > * {
                        display: none;
                    }

                    .Header {
                        &-Title,
                        &-LogoWrapper,
                        &-Button {
                            &_isVisible {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }

    &_name_popup {
        @include mobile {
            z-index: 450;
        }
    }

    &-MyAccountContainer {
        display: flex;
        margin-inline-start: auto;
    }
}
