/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

:root {
    // Colors
    --color-black: #{$black};
    --color-white: #{$white};
    --color-gray: #f3f3f2;
    --color-dark-gray: #dddddd;
    --color-karaka: #0a0903;
    --primary-divider-color: #e0e0e0;
    --secondary-error-color: #fff;
    --primary-error-color: #c04545;
    --secondary-success-color: var(--color-karaka);
    --primary-success-color: #76c46e;
    --secondary-info-color: var(--color-karaka);
    --primary-info-color: #cfc255;
    --body-content-color: var(--color-black);
    --filter-bg-color: #f4f1ec;

    --doncigarro-primary-color: #D29143;
    --doncigarro-secondary-color: #1E1E1E;

    // Placeholders
    --placeholder-gradient-color: #d9d9d9;
    --placeholder-image: linear-gradient(to right, #ebebeb 0, var(--placeholder-gradient-color) 15%, #ebebeb 30%);
    --placeholder-size: 1200px 100%;
    --placeholder-animation: placeholderAnimation 2s linear infinite;

    --shake-animation: shake 820ms .5ms cubic-bezier(.36, .07, .19, .97) both;

    // Declare imported primary colors to change the theme's colors
    // "imported_..." variables are set up in Admin panel -> Stores -> Configuration -> Scandipwa -> Color Configuration
    // They have higher priority than "default_..." variables defined in code.
    --primary-base-color: var(--doncigarro-primary-color);
    --primary-dark-color: var(--doncigarro-primary-color);
    --primary-light-color: var(--doncigarro-primary-color);
    --secondary-base-color: var(--doncigarro-secondary-color);
    --secondary-dark-color: var(--doncigarro-secondary-color);
    --secondary-light-color: var(--doncigarro-secondary-color);
}
