/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.AgeVerification {
    position: fixed;
    display: flex;
    inset-block: 0;
    inset-inline: 0;
    z-index: 999;
    background-color: rgba(0,0,0,.6);
    backdrop-filter: blur(10px);
    align-items: center;
    justify-content: center;
    color: white;

    &-Content {
        max-width: 500px;
        padding: 48px;
        background-color: rgba(0,0,0,.5);

        @include mobile {
            padding: 68px 32px;
        }
    }

    &-TooYoung {
        position: absolute;
        inset-block-start: 0;
        inset-inline-start: 0;
        width: 100%;
        height: 0;
        z-index: 5;
        background-color: black;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        padding: 0;
        overflow: hidden;
        transition: height 1s ease-in-out, padding 1s ease-in-out;

        &_show {
            padding: 10px 50px;
            height: 50px;

            @include mobile {
                height: 68px;
            }
        }
    }

    &-Title {
        color: white;
        margin: 0 0 32px;
        text-align: center;
        font-size: 32px;
        font-weight: 600;
        line-height: 38px;
    }

    &-Text {
        color: white;
        margin-block-end: 32px;
        text-align: center;
        font-size: 18px;
        line-height: 24px;
    }

    &-Input-Wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }

    &-FormRow {
        width: calc(100% / 3);
    }

    &-FormLabel {
        display: none;
    }

    &-FormInput {
        width: 100%;
        height: 52px;
        font-size: 18px;
        padding: 10px 20px;
        background: none;
        border: 1px solid rgba(255,255,255, .5);
        color: white;

        &_invalid {
            border-color: red;
        }
    }

    &-SubmitButton {
        background-color: white;
        color: black;
        height: 48px;
        width: 100%;
        margin-block-start: 32px;
        font-size: 18px;
        cursor: pointer;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number] {
        -moz-appearance: textfield;
    }
}
